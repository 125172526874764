import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import DownloadPage from '../templates/Download';
const host = process.env.GATSBY_HOST;

const download = {
  title: 'downloadTitle',
  subTitle: 'downloadSubTitle',
  links: 'downloadLinks',
  linksTitle: 'downloadLinksTitle',
  linkInstruction: 'downloadLinkInstruction',
  selectText: 'downloadSelectTitle',
  downloadList: [
    {
      disabled: false,
      value: 'AWS',
      key: 'aws',
      version: 'Corezoid version 6.6.0',
      link: 'https://aws.amazon.com/marketplace/pp/Corezoidcom-Corezoid-Process-Engine/B013AYOIYG',
      image: 'aws-logo.png',
      id: 'aws',
    },
    {
      disabled: false,
      value: 'Microsoft Azure',
      key: 'azure',
      version: 'Corezoid version 6.6.0',
      link: 'https://azuremarketplace.microsoft.com/en-us/marketplace/apps/middleware.corezoid-process-engine?tab=PlansAndPrice',
      image: 'azure-logo.png',
      id: 'azure',
    },
    {
      disabled: false,
      value: 'Google Cloud Engine',
      key: 'gce',
      version: 'Corezoid version 6.6.0',
      link: 'https://console.cloud.google.com/marketplace/product/corezoid-public/corezoid-actor-engine',
      image: 'gce-logo.png',
      id: 'gce',
    },
    {
      disabled: false,
      value: 'VMware',
      key: 'vmware',
      version: 'Corezoid version 6.6.0',
      link: 'https://middleware-repo.s3.eu-west-1.amazonaws.com/vmware/6.6.0/corezoid-6.6.0.zip',
      image: 'vmware-logo.png',
      id: 'vmware',
      helpLinks: [
        {
          link: 'https://doc.corezoid.com/docs/deploy-corezoid-on-vmware-1',
          value: 'downloadLinksMWDocs',
        },
        {
          link: 'https://doc.corezoid.com/docs/deploy-corezoid-on-vmware-fusion-1',
          value: 'downloadLinksMWInstall',
        },
      ],
    },
    {
      disabled: false,
      value: 'Kubernetes',
      key: 'kubernetes',
      version: 'Corezoid version 6.6.0',
      link: 'https://github.com/corezoid/helm/tree/master/corezoid',
      image: 'kubernetes-logo.png',
      id: 'kubernetes',
    },
  ],
  versionText: 'downloadVersion',
  sizeText: 'downloadSize',
  terms: 'downloadTerms',
  form: {
    type: 'download',
    downloadText: 'downloadDone',
    downloadLink: 'downloadLink',
    downloadBack: 'downloadBack',
    fields: [
      {
        type: 'email',
        label: 'formEmail',
        value: '',
      },
    ],
    button: {
      text: 'downloadButtonText',
      textDisabled: 'downloadButtonTextDisabled',
      link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
    },
  },
};

const Events = (props) => (
  <Layout {...props}>
    <DownloadPage data={download} />
  </Layout>
);

export default withIntl(Events);
